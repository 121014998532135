import { Box, Typography } from '@mui/material';
import { useDevice } from 'hooks/useDevice';
import { useNavigate } from 'react-router-dom';
import { MARKET_ROUTE } from 'router/routes';

const CustomLogo = () => {
    const navigate = useNavigate();
    const { m } = useDevice();

    return (
        <Box
            sx={{ display: 'flex', alignItems: 'center', gap: 0.5, cursor: 'pointer' }}
            onClick={() => {
                navigate(MARKET_ROUTE?.root());
            }}
        >
            <Box
                sx={{
                    boxShadow: '0 0 5px 1px #414141c5',
                    borderRadius: '50%',
                    border: '2.2px solid #383838',
                }}
            >
                <Box
                    sx={{
                        width: m ? 36 : 45,
                        height: m ? 36 : 45,
                        borderRadius: '50%',
                        overflow: 'hidden',
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative',
                    }}
                >
                    <img
                        src={require(`assets/img/main_logo.webp`)}
                        style={{
                            width: m ? 40 : 48,
                            height: m ? 40 : 48,
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50.1%,-51.2%)',
                        }}
                        alt="logo"
                    />
                </Box>
            </Box>

            <Box
                mt={0.5}
                sx={{
                    position: 'relative',
                    display: 'flex',
                }}
            >
                <Typography
                    sx={{
                        fontFamily: 'Young Serif',
                        fontSize: m ? 25 : 36,
                        fontWeight: 600,
                        textShadow: '#0000006a 0 0 2px',
                        mr: 0.1,
                    }}
                >
                    C
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Young Serif',
                        fontSize: m ? 25 : 36,
                        fontWeight: 500,
                        color: '#3f5370',
                        textShadow: '#2c4974 0 0 2.25px',
                    }}
                >
                    o
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Young Serif',
                        fontSize: m ? 25 : 36,
                        fontWeight: 500,
                        color: '#3f5370',
                        textShadow: '#2c4974 0 0 2.25px',
                    }}
                >
                    c
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Young Serif',
                        fontSize: m ? 25 : 36,
                        fontWeight: 500,
                        color: '#3f5370',
                        textShadow: '#2c4974 0 0 2.25px',
                    }}
                >
                    k
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Young Serif',
                        fontSize: m ? 25 : 36,
                        fontWeight: 500,
                        color: '#3f5370',
                        textShadow: '#2c4974 0 0 2.25px',
                    }}
                >
                    t
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Young Serif',
                        fontSize: m ? 25 : 36,
                        fontWeight: 500,
                        color: '#3f5370',
                        textShadow: '#2c4974 0 0 2.25px',
                    }}
                >
                    a
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Young Serif',
                        fontSize: m ? 25 : 36,
                        fontWeight: 500,
                        color: '#3f5370',
                        textShadow: '#2c4974 0 0 2.25px',
                    }}
                >
                    i
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Young Serif',
                        fontSize: m ? 25 : 36,
                        fontWeight: 500,
                        color: '#3f5370',
                        textShadow: '#2c4974 0 0 2.25px',
                    }}
                >
                    l
                </Typography>
                <Typography
                    sx={{
                        ml: 1,
                        fontFamily: 'Young Serif',
                        fontSize: m ? 25 : 36,
                        fontWeight: 600,
                        textShadow: '#0000006a 0 0 2px',
                        mr: 0.1,
                    }}
                >
                    S
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Young Serif',
                        fontSize: m ? 25 : 36,
                        color: '#3f5370',
                        textShadow: '#2c4974 0 0 2.25px',
                        fontWeight: 500,
                    }}
                >
                    h
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Young Serif',
                        fontSize: m ? 25 : 36,
                        fontWeight: 500,
                        color: '#3f5370',
                        textShadow: '#2c4974 0 0 2.25px',
                    }}
                >
                    o
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Young Serif',
                        fontSize: m ? 25 : 36,
                        fontWeight: 500,
                        color: '#3f5370',
                        textShadow: '#2c4974 0 0 2.25px',
                    }}
                >
                    p
                </Typography>
            </Box>
        </Box>
    );
};

export default CustomLogo;
