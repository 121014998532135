import { GenderType, MarketPlaceType, StoreType, ViewModeType } from 'store_constants/types';

export const PASMA_DESIGN = {
    storage_key: 'psm_design',
    active: false, // Поле которое запускает магазин true - работает false - остановлен / по тех. причина
    status: null,
    required_registration: false,
    URL: [],
    web_lang: 'uk', // html lang
    store_name: 'Pasma Design',
    store_code: 'pasma_design',
    // store_code: 'ALBERTO_BINI',
    base_url: 'https://kremen-belts.com/api',
    app_lang: 'ua',
    supported_countries: null,
    telegram_sender: true,
    links: [],
    managers: [
        {
            firstName: 'Cocktail Manager',
            lastName: '',
            emailAddress: 'salesnest.info@gmail.com',
            options: { manager: true },
            contacts: {
                emailAddress: 'salesnest.info@gmail.com',
                phone: '+380668652127',
                viber: '',
                whatsapp: '+380668652127',
                telegram: 'SNBusinessApp',
            },
        },
    ],
    options: {
        discounts: false,
        webUrl: null,
        telegram_bot: [
            {
                token: '7115237316:AAFgbrVadLWMkPt3TEdmravEa37jO9N3WSQ',
                chatId: '480774886',
            },
        ],
        product_image_options: { width: 6, height: 8 },
        main_price_multiplication: 1,
        sale_price_multiplication: 1,
        currency_multiplication: 1,
        retail_price_multiplication: 1,
        min_items_to_buy: 1,
        store_type: StoreType?.sales,
        home_page_active: true,
        informative_page_active: true,
        custom_currency: null,
        custom_logo: false,
        orderPriority: 35,
        plan_options: {
            prices: true,
            sizes: true,
            contacts: true,
            feedback: true,
            categories: true,
            productShare: true,
            cart: true,
            favorites: true,
            tableSizes: true,
            marketPlace: { isShown: true, type: MarketPlaceType?.drop },
        },
    },
    web_head_data: {
        store_title: '',
        store_description: ``,
        google_analytics_id: 'G-SPMQE8GHGM',
        google_ads_id: '',
        conversion_id: '',
        store_logo: '',
        store_poster: '',
        keywords: {},
    },
    user_options: { view_mode: ViewModeType?.card },
    alerts: [],
    store: {
        bannerUrl: require(`../banners/pasma.webp`),
        descriptions: [
            {
                language: 'ua',
                description:
                    'Бренд PASMAdesign спеціалізується на в’язаному жіночому одязі, виготовленому вручну з натуральних матеріалів. Ми підтримуємо індивідуальне виробництво, віддаючи перевагу унікальності кожної речі, щоб вона ідеально підходила своїй власниці. Наша мета – зменшити надлишкове виробництво і зберегти екологію.',
            },
            {
                language: 'en',
                description:
                    "PASMAdesign is a brand specializing in hand-knitted women's clothing made from natural materials. We support individual production, prioritizing the uniqueness of each piece to ensure it perfectly fits its owner. Our goal is to reduce excess production and protect the environment.",
            },
        ],
        types: [14, 42, 39, 64, 1000],
        gender: [GenderType?.FEMALE],
        rank: 3,
    },
};
