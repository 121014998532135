import { Box, Button, Collapse, Typography } from '@mui/material';
import { memo, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import LargeStoreCard from './LargeStoreCard';
import { FormattedStore } from 'types/app_models';
import Grid from '@mui/material/Unstable_Grid2';
import ImageComponent from 'components/atoms/Media/Image';
import { STORE_ROUTE } from 'router/routes';
import { APP_STORES } from 'store_constants/useStoreConfig';
import { StoresListContextInterface } from 'types/outlet_context_models';
import { Color, Colors } from 'constants/colors';
import { useDevice } from 'hooks/useDevice';
import PlaceIcon from '@mui/icons-material/Place';
import useGetStoreTypes from 'store_constants/useGetStoreTypes';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useAddToFavorites } from 'layouts/hooks/useAddToFavorites';
import { useAddToCart } from 'layouts/hooks/useAddToCart';
import PublicIcon from '@mui/icons-material/Public';
import ManIcon from '@mui/icons-material/Man';
import WomanIcon from '@mui/icons-material/Woman';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import { GenderType } from 'store_constants/types';
// import BookmarkIcon from '@mui/icons-material/Bookmark';
// import { DialogWindowType } from 'layouts/hooks/useFormsApp';
// import { useStoresApi } from 'api/useStoresApi';
// import { telegramSender } from 'utils/telegramSender';
// import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone';
import WcTwoToneIcon from '@mui/icons-material/WcTwoTone';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SalesMarker from 'components/atoms/Marker/SalesMarker';
import { telegramSender } from 'utils/telegramSender';

interface StoreCardProps {
    data: FormattedStore;
    subscribeList: FormattedStore[];
    refreshSubscriptionsList;
}

const StoreListCard = memo<StoreCardProps>(({ data, subscribeList, refreshSubscriptionsList }) => {
    const navigate = useNavigate();
    const { lang, string }: StoresListContextInterface = useOutletContext();
    const storeTypesList = APP_STORES?.find(store => store?.store_code === data?.code)?.store?.types;
    const { currentStoreTypes } = useGetStoreTypes({ lang, storeTypes: storeTypesList });
    const { sx, slx } = useDevice();
    const [isOpenDescription, setIsOpenDescription] = useState(false);
    const { favoriteItems } = useAddToFavorites({});
    const { cartItems } = useAddToCart({});
    const [openRedirectMenu, setOpenRedirectMenu] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const cartNumbers = cartItems?.filter(item => item?.storeCode === data?.code)?.length;
    const favNumbers = favoriteItems?.filter(item => item?.storeCode === data?.code)?.length;
    // const subscribed = subscribeList?.map(el => el.code)?.includes(data?.code);

    // const { mutateAsync: addStoreToSubscriptions } = useStoresApi().useAddStoreToFavorite();
    // const { mutateAsync: removeStoreToSubscriptions } = useStoresApi().useDeleteStoreToFavorite();

    const CardDetails = () => {
        return (
            <Grid
                container
                onClick={e => {
                    e?.stopPropagation();
                    if (openRedirectMenu) return;
                    navigate(STORE_ROUTE?.root(data?.code));
                    telegramSender({
                        action: `VISIT ${data?.code}`,
                    });
                }}
            >
                {data?.options?.discounts && <SalesMarker />}
                <Grid
                    container
                    xs={12}
                    display="flex"
                    justifyContent="space-between"
                    sx={{ cursor: 'pointer', minHeight: sx ? 160 : 250 }}
                >
                    <Grid xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box
                            pt={1}
                            px={1.5}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: 0.25,
                                cursor: 'pointer',
                                height: sx ? 45 : 50,
                                backgroundImage: `linear-gradient(90deg,  #d6d6d67a, #ffffff32)`, // Пе
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.25, position: 'relative' }}>
                                <Box ml={-1.75} mr={0.25} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography
                                        sx={{
                                            borderRadius: 1,
                                            borderTopLeftRadius: 12,
                                            p: 0.2,
                                            pl: 1,
                                            lineHeight: 1,
                                            ml: sx ? 0 : 0.5,
                                            fontSize: sx ? 22 : 26,
                                            color: data?.options?.webUrl ? Color?.PRIMARY : Colors?.BLACK,
                                            fontFamily: 'Serif',
                                            fontWeight: 700,
                                            letterSpacing: 0,
                                        }}
                                        onClick={e => {
                                            if (!data?.options?.webUrl) return;
                                            e.stopPropagation();
                                            setAnchorEl(e.currentTarget);
                                            setOpenRedirectMenu(true);
                                        }}
                                    >
                                        {`${data?.name?.trim()}`}
                                    </Typography>
                                    {data?.options?.webUrl && (
                                        <PublicIcon fontSize="medium" sx={{ color: Color?.PRIMARY }} />
                                    )}

                                    <Menu
                                        id="redirect-menu"
                                        anchorEl={anchorEl}
                                        open={openRedirectMenu}
                                        onClose={() => {
                                            console.log('first');
                                            setOpenRedirectMenu(prev => !prev);
                                            setAnchorEl(null);
                                        }}
                                        MenuListProps={{
                                            'aria-labelledby': 'redirect-menu',
                                        }}
                                    >
                                        {[
                                            {
                                                name: `${string?.go_to} ${data?.name?.trim()} Web Site`,
                                                onClick: () => {
                                                    window.open(
                                                        data?.options?.webUrl || '/',
                                                        '_blank',
                                                        'noopener,noreferrer'
                                                    );
                                                },
                                            },
                                            {
                                                name: `${string?.go_to} Cocktail #${data?.name?.trim()}`,
                                                onClick: () => {
                                                    navigate(STORE_ROUTE?.root(data?.code));
                                                },
                                            },
                                        ]?.map(el => (
                                            <MenuItem
                                                sx={{
                                                    height: '10px', // Устанавливаем фиксированную высоту
                                                    lineHeight: '10px', // Контролируем высоту строки, чтобы текст вписался
                                                    py: 2, // Убираем вертикальные отступы
                                                    minHeight: 'unset', // Отменяем минимальную высоту по умолчанию
                                                }}
                                                key={el.name}
                                                onClick={e => {
                                                    el.onClick();
                                                }}
                                            >
                                                {el.name}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Box>
                                {/* <EmojiEventsTwoToneIcon
                                    sx={{
                                        color:
                                            data?.store?.rank === 1
                                                ? Colors?.GOLD
                                                : data?.store?.rank === 2
                                                ? Colors?.GRAY_900
                                                : Colors?.BRONZE,
                                    }}
                                /> */}
                                {data?.store?.gender?.includes(GenderType?.FEMALE) &&
                                    !data?.store?.gender?.includes(GenderType?.MALE) &&
                                    !data?.store?.gender?.includes(GenderType?.FAMILY) && (
                                        <WomanIcon sx={{ color: Colors?.GRAY_900 }} />
                                    )}
                                {data?.store?.gender?.includes(GenderType?.MALE) &&
                                    !data?.store?.gender?.includes(GenderType?.FEMALE) &&
                                    !data?.store?.gender?.includes(GenderType?.FAMILY) && (
                                        <ManIcon sx={{ color: Colors?.GRAY_900 }} />
                                    )}
                                {data?.store?.gender?.includes(GenderType?.KIDS) && (
                                    <EscalatorWarningIcon sx={{ color: Colors?.GRAY_900 }} />
                                )}
                                {data?.store?.gender?.includes(GenderType?.FAMILY) && (
                                    <FamilyRestroomIcon sx={{ color: Colors?.GRAY_900 }} />
                                )}
                                {data?.store?.gender?.includes(GenderType?.MALE) &&
                                    data?.store?.gender?.includes(GenderType?.FEMALE) &&
                                    !data?.store?.gender?.includes(GenderType?.FAMILY) && (
                                        <WcTwoToneIcon sx={{ color: Colors?.GRAY_900 }} />
                                    )}
                            </Box>
                            <Typography
                                sx={{
                                    mt: -0.5,
                                    ml: sx ? 1 : 1.5,
                                    fontSize: sx ? 10 : 12,
                                    color: Colors?.GRAY_900,
                                    fontWeight: 500,
                                }}
                            >
                                {data?.options?.webUrl?.replace(/^https?:\/\//, '')}
                            </Typography>
                        </Box>

                        <Box
                            px={1}
                            onClick={e => {
                                e?.stopPropagation();
                            }}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'default',
                                zIndex: 10,
                                gap: 1,
                            }}
                        >
                            <Box sx={{ cursor: 'default', display: 'flex', alignItems: 'center', gap: 0.05 }}>
                                <Typography variant="h4" sx={{ color: Colors?.BLACK }}>
                                    {favNumbers}
                                </Typography>
                                <FavoriteIcon fontSize="small" sx={{ color: Colors?.GRAY_500 }} />
                            </Box>
                            <Box
                                sx={{
                                    cursor: 'default',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 0.05,
                                }}
                            >
                                <Typography variant="h4" sx={{ color: Colors?.BLACK }}>
                                    {cartNumbers}
                                </Typography>
                                <ShoppingCartIcon fontSize="small" sx={{ color: Colors?.GRAY_500 }} />
                            </Box>
                            {/* <Box
                                onClick={e => {
                                    e?.stopPropagation();

                                    if (!auth) {
                                        return handleOpenDialog(DialogWindowType?.LOGIN);
                                    }

                                    if (!subscribed) {
                                        addStoreToSubscriptions({ storeCode: data?.code }).then(_ => {
                                            refreshSubscriptionsList();
                                            telegramSender({
                                                action: `ПОДПИСАЛСЯ на ${data?.name}`,
                                            });
                                        });
                                    }
                                    if (subscribed) {
                                        removeStoreToSubscriptions({ storeCode: data?.code }).then(_ => {
                                            refreshSubscriptionsList();
                                            telegramSender({
                                                action: `Отписался от ${data?.name}`,
                                            });
                                        });
                                    }
                                }}
                                sx={{ cursor: 'pointer' }}
                            >
                                <IconButton
                                    disabled={!auth}
                                    size="small"
                                    sx={{
                                        ml: 0.5,
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 0.25,
                                        boxShadow: '0 0 2px 2px #cccccc9e',
                                        cursor: 'pointer',
                                        width: 28,
                                        height: 28,
                                    }}
                                >
                                    <BookmarkIcon
                                        fontSize="small"
                                        sx={{
                                            color: subscribed ? Colors?.ORANGE : Colors?.GRAY_900,
                                        }}
                                    />
                                </IconButton>
                            </Box> */}
                        </Box>
                    </Grid>

                    <Grid mt={sx ? 1 : 0.25} xs={8.5} px={1.5} container sx={{ height: sx ? 110 : 160 }}>
                        {currentStoreTypes?.map((type, idx) => {
                            if (idx > 9) return null;
                            return (
                                <Grid
                                    key={idx}
                                    xs={6}
                                    sx={{ display: 'flex', alignItems: 'center', gap: 0.6, height: sx ? 10 : 30 }}
                                >
                                    <PlaceIcon sx={{ color: Colors?.ORANGE, fontSize: sx ? 17 : 22 }} />

                                    <Typography sx={{ fontSize: sx ? 12 : 14, color: Colors?.BLACK }}>
                                        {type}
                                    </Typography>
                                </Grid>
                            );
                        })}
                    </Grid>
                    <Grid
                        pr={1}
                        xs={sx ? 3.5 : 3}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Grid
                            mb={sx ? 3 : 0}
                            sx={{
                                width: sx ? 90 : 115,
                                height: sx ? 90 : 115,
                                borderRadius: 4,
                                overflow: 'hidden',
                                maxHeight: 'fit-content',
                                border: `1px solid ${Colors?.GRAY_300}`,
                                boxShadow: '0 0 5px 2px #cecece5e',
                                background: Colors?.WHITE,
                                zIndex: 10,
                            }}
                        >
                            <ImageComponent
                                imageUrl={
                                    data?.logo?.path.length > 1
                                        ? data?.logo?.path
                                        : require('assets/img/cut_main_log.png')
                                }
                                wrapperHeight="100%"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    pt={0.5}
                    px={1.5}
                    pb={2}
                    className="CollapseStoreDescription"
                    xs={12}
                    display="flex"
                    justifyContent="space-between"
                    sx={{
                        cursor: 'pointer',
                        background: '#ffffff78',
                        width: '100%',
                        height: slx ? '' : 95,
                        zIndex: 10,
                    }}
                >
                    <Collapse
                        in={isOpenDescription || !slx}
                        collapsedSize={25}
                        onClick={e => {
                            e?.stopPropagation();
                            setIsOpenDescription(!isOpenDescription);
                        }}
                    >
                        <Typography
                            sx={{
                                color: Colors?.BLACK,
                                fontWeight: 400,
                                letterSpacing: 0.7,
                                lineHeight: 1.2,
                                fontSize: slx ? 11 : 12,
                            }}
                        >
                            {
                                APP_STORES?.find(store => store?.store_code === data?.code)?.store?.descriptions?.find(
                                    desc => desc?.language === lang
                                )?.description
                            }
                        </Typography>
                    </Collapse>
                    {slx && (
                        <Box sx={{ position: 'absolute', right: 4, bottom: -5 }}>
                            <Button
                                onClick={e => {
                                    e?.stopPropagation();
                                    setIsOpenDescription(!isOpenDescription);
                                }}
                                sx={{
                                    color: Colors?.GRAY_900,
                                    fontWeight: 700,
                                    letterSpacing: 0.33,
                                    lineHeight: 1.25,
                                    fontSize: slx ? 11 : 12,
                                }}
                            >
                                {isOpenDescription ? string?.less : `...${string?.more}`}
                            </Button>
                        </Box>
                    )}
                </Grid>
            </Grid>
        );
    };

    return (
        <>
            <LargeStoreCard CardDetails={CardDetails} opacity={true} data={data} />
        </>
    );
});

export default StoreListCard;
